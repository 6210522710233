import * as v from "valibot"
import { createAssertFn, createValidateFn, numberOrString, numberToString } from "@/utils/validator"
import { SearchHit, SearchTrackOptions } from "./types"
import { Equals, Expect, SearchEventMetadata } from "@/types"

const nonEmptyString = v.pipe(v.string(), v.minLength(1))

const clickSchema = v.looseObject({
  productId: v.pipe(numberOrString, numberToString, v.string()),
  url: v.optional(v.pipe(v.string(), v.url()))
})

const searchImpressionSchema = v.object({
  query: nonEmptyString,
  resultId: v.string(),
  hasResults: v.boolean(),
  isAutoComplete: v.boolean(),
  isAutoCorrect: v.boolean(),
  isKeyword: v.boolean(),
  isOrganic: v.boolean(),
  isRefined: v.boolean(),
  isSorted: v.boolean(),
  refinedQuery: v.optional(v.string())
})

const categorySchema = v.object({
  category: nonEmptyString,
  categoryId: v.optional(nonEmptyString)
})

const categoryIdSchema = v.object({
  category: v.optional(nonEmptyString),
  categoryId: nonEmptyString
})

export const categoryImpressionSchema = v.union([categorySchema, categoryIdSchema])

const typeSchema = v.picklist(["serp", "autocomplete", "category"])

export const assertValidClick = createAssertFn("SearchHit", clickSchema)
export const validateSearchImpression = createValidateFn("SearchImpression", searchImpressionSchema)
export const validateCategoryImpression = createValidateFn("CategoryImpression", categoryImpressionSchema)

export const assertValidType = createAssertFn("SearchTrackOptions", typeSchema)

/** @hidden */
export type tests = [
  Expect<Equals<v.InferOutput<typeof clickSchema>, SearchHit>>,
  Expect<Equals<v.InferOutput<typeof searchImpressionSchema>, SearchEventMetadata>>,
  Expect<Equals<v.InferOutput<typeof typeSchema>, SearchTrackOptions>>
]
